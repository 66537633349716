.total_stats {
  display: flex;
  width: 230px;
  height: 100px;
  border-radius: 15px; 
  background-color: #000;
}

.total_stats__left {
  display: flex;
  width: 160px;
  height: 100%; 
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 12px;
  gap: 15px;
}

.total_stats__left span {
  display: flex;
  width: 100%;
  height: 18px; 
  font-size: 16px;
  font-weight: 500;
  color: #fff; 
  align-items: center;
  padding-inline: 15px;
}

.total_stats__left span:nth-of-type(1) {
  font-size: 25px;
}

.total_stats__right {
  display: flex;
  width: 50px;
  height: 100%; 
}

@media (max-width: 750px) {
  .total_stats {
    display: flex;
    width: 100%;
    min-height: 100px; 
    flex-wrap: wrap;
  }
  
  .total_stats__left {
    display: flex;
    width: calc(100% - 50px);
    height: 100%;  
    padding-bottom: 12px; 
    gap: 15px;
  }
  
  .total_stats__left span {
    display: flex;
    width: 100%;
    height: 18px; 
    font-size: 15px; 
  }
  
  .total_stats__left span:nth-of-type(1) {
    font-size: 20px;
  }
  
  .total_stats__right {
    display: flex;
    width: 50px;
    height: 100%;  
  }
}


/*For student portal*/
.total_statsW {
  display: flex;
  width: 230px;
  height: 200px;
  border-radius: 15px; 
  background-color: #fff;
  text-align: center;
}

.total_stats__leftW {
  display: flex;
  width: 160px;
  height: 100%; 
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 12px;
  gap: 15px;
}

.total_stats__leftW span {
  display: flex;
  width: 100%;
  height: 18px; 
  font-size: 16px;
  font-weight: 500;
  color: #000; 
  align-items: center;
  padding-inline: 15px;
}

.total_stats__leftW span:nth-of-type(1) {
  font-size: 25px;
}

.total_stats__rightW {
  display: flex;
  width: 50px;
  height: 100%; 
}

@media (max-width: 750px) {
  .total_statsW {
    display: flex;
    width: 100%;
    min-height: 100px; 
    flex-wrap: wrap;
  }
  
  .total_stats__leftW {
    display: flex;
    width: calc(100% - 50px);
    height: 100%;  
    padding-bottom: 12px; 
    gap: 15px;
  }
  
  .total_stats__leftW span {
    display: flex;
    width: 100%;
    height: 18px; 
    font-size: 15px; 
  }
  
  .total_stats__leftW span:nth-of-type(1) {
    font-size: 20px;
  }
  
  .total_stats__rightW {
    display: flex;
    width: 50px;
    height: 100%;  
  }
}
