.report-card-container {
    width: 70%;
    margin: 20px auto;
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .report-card-header h2 {
    font-size: 1.2rem;
  }
  
  .report-card-header p {
    font-size: 0.9rem;
  }
  
  .school-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .school-logo {
    width: 100px;
    height: 100px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  
  .school-details h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .student-info p {
    margin: 0;
    font-size: 0.9rem;
  }
  
  .report-details {
    display: flex;
    justify-content: space-between;
  }
  
  .subject-table {
    width: 60%;
    border-collapse: collapse;
  }
  
  .subject-table th, .subject-table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
  }
  
  .attendance, .grade-summary, .school-stamp, .principal-comment, .grading-system {
    margin-top: 20px;
  }
  
  .grading-system ul {
    list-style: none;
    padding: 0;
  }
  
  .grading-system li {
    margin-bottom: 5px;
  }
  
  .report-card-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .print-button, .download-button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
  }
  