/* Nav.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.navbar-logo h3 {
  font-size: 1.2rem;
  color: #4CAF50;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-item {
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}

.navbar-item p {
  margin-left: 8px;
  font-size: 0.9rem;
}

.menu-toggle {
  display: none;
  cursor: pointer;
}

.menu-toggle svg {
  font-size: 1.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar-menu {
    display: none;
  }

  .navbar-menu.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
  }

  .menu-toggle {
    display: block;
  }
}


.avatar-dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 150px;
  background-color: #B2D4F9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1000;
}

.dropdown-item {
  color: #333;
  text-decoration: none;
  display: flex;
  /* Use flexbox */
  align-items: center;
  /* Center items vertically */
  padding: 10px;
  /* Add padding for spacing */
  cursor: pointer;
}


.dropdown-item:hover {
  background-color: #f0f0f0;
}

.navBarIcons {
  width: 24px;
  height: 24px;
}
.navBarNotif{
  width: 16px; 
  height: 16px; 
  margin-right: 8px;
}