body {
  background-image: url("../../public/images/bg.png");
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-color: #fff;
  background-attachment: fixed;
}

.register_parent h1 {
  display: flex;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  height: fit-content;
  margin-inline: auto;
  margin-block: 20px 40px;
  justify-content: center;
}

.register_parent__form_container {
  display: flex;
  width: 900px;
  max-width: 100%;
  height: fit-content;
  border: 1px solid #000;
  border-radius: 30px;
  margin-block: 30px 50px;
  margin-inline: auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.register_parent__form_container__form {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  padding-block: 50px;
  padding-inline: 30px;
  gap: 30px;
  justify-content: space-between;
}

.register_parent__form_container__form h2 {
  display: flex;
  width: 100%;
  height: fit-content;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
}

.register_parent__form_container .form_input_container {
  width: 400px; 
  gap: 10px;
  flex-grow: 1;
}

.register_parent__form_container .form_input_container label { 
  min-height: fit-content !important;
}

.register_parent__form_container .form_input_container input {  
  background-color: #fff;
  height: 50px !important;
  border-radius: 15px;
}

.register_parent__form_container p {
  display: flex;
  width: 100%;
  margin-block: 20px 0;
  flex-wrap: wrap;
}

.register_parent__form_container__form a {
  text-decoration: underline;
}

.register_parent__form_container__form a:hover {
  color: gray;
}

.register_parent__form_container button {
  display: flex;
  width: 300px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #38B349;
  color: #fff;
  margin-inline: auto; 
}

.register_parent__form_container button:hover {
  background-color: #000;
}

.province_error, .district_error {
  display: none !important;
  min-width: 200px !important;
  min-height: 50px;
  justify-content: space-between;
  align-items: center;
  background-color: #B2D4F9;
  position: absolute;
  top: 30px;
  left: 5px;
  z-index: 2;
  font-size: 14px;
  box-shadow: 0 10px 10px #00000027, 10px 0 10px #00000027, -10px 0 10px #00000027;
  padding-inline: 5px;
  border-radius: 5px;
}

.register_parent .close_parent {
  display: flex;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  color: red;
  border-radius: 15px;
  justify-content: center;
  align-items: flex-end;
  background: transparent;
  font-size: 30px;
  cursor: pointer; 
}

.register_parent .close_parent:hover {
  background-color: transparent;
}

.displayed_flex {
  display: flex !important;
}

@media (max-width: 750px) { 
  h1 {
    display: flex;
    font-size: 18px; 
    margin-block: 20px 30px; 
  } 
  
  .register_parent__form_container__form { 
    padding-block: 30px;
    padding-inline: 20px;
    gap: 20px; 
  }
   
  .register_parent__form_container p { 
    margin-block: 10px 0; 
  }

  .register_parent__form_container button { 
    flex-grow: 1;
  }
}
 