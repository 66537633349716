* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body {
    font-family: Arial, sans-serif;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 15px;
    background-color: #000;
    z-index: 1000;
    opacity: 0.7;
}

.logo img {
    width: 200px;
}

/* Smaller logo on scroll */
.navbar.scrolled .logo img {
    width: 100px;
}

.navbar .scrolled{
    background-color: #000;
}

.InputContainer {
    height: 40px;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    border-radius: 35px;
    overflow: hidden;
    cursor: pointer;
    padding-left: 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
    position: relative;
}

.input {
    width: 500px;
    height: 100%;
    border: none;
    outline: none;
    font-size: 0.9em;
    caret-color: rgb(255, 81, 0);
    border-radius:35px;
    color: #000;
}

.labelforsearch {
    cursor: text;
    padding: 0px 12px;
}

.searchIcon {
    width: 24px;
    color: #39B54A;
}

.micIcon {
    width: 12px;
}

.micButton {
    padding: 0px 15px 0px 12px;
    border: none;
    background-color: transparent;
    height: 40px;
    cursor: pointer;
    transition-duration: 0.3s;
}

.searchIcon path {
    fill: #39B54A;
}

.micIcon path {
    fill: rgb(255, 81, 0);
}

.micButton:hover {
    background-color: rgb(139, 219, 119);
    transition-duration: 0.3s;
}

.nav-buttons {
    display: flex;
}

.nav-buttons .btn {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    padding: 8px 15px;
    margin-left: 10px;
    background-color: rgba(26,182,26,0.7);
    border: 2px solid transparent;
    border-radius: 25px;
    transition: background-color 0.3s ease, border 0.3s ease;
    
}


.nav-buttons .btn:hover {
    background-color: rgb(108, 206, 83);
    border: 2px solid transparent;
}

.hero {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: cover;
    color: white;

    
}

.background-video {
    position: relative; /*Needed for absolute positioning of ovelaying*/
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -2;
    filter: brightness(0.6);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}
.fullscreen-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 1;
}


.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(14, 14, 14, 0.7);
    background-image: radial-gradient(circle, rgba(255, 255, 255, 0.2)x, transparent 1px);
    background-size: 10px 10px;
    opacity: 30%;
    z-index: -1;
}

.hero-content {
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    z-index: 1;
    margin-top: 15%;
    width: 100%;
    height: fit-content;
    padding-block: 20px; 

}

.hero-content h1 {
    font-size: 4rem;
    margin-bottom: 10px;
}

.hero-content p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    margin-top: 30px;
}

.btn2{
    padding: 8px 15px;
    background-color: rgba(255,255,255,0.1);
    color: white ;
    text-decoration: none;
    font-size: 1rem;
    border: 2px solid white;
    transition: background-color 0.3s ease;
    border-radius: 35px;
    
}
.btn3 {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    padding: 8px 15px;
    margin-left: 10px;
    
    border: 2px solid transparent;
    border-radius: 25px;
    transition: background-color 0.3s ease, border 0.3s ease;

}

.hero-content .btn {
    padding: 8px 15px;
    background-color:green;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    border: 2px solid green;
    border-radius: 20px;
    transition: background-color 0.3s ease;
}


.welcome1 {
    color:rgba(32, 189, 53, 0.788);
}

/* Suggestion List Styles */

.suggestion-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 250px;
    overflow-y: auto;
    margin: 25%;
    padding: 0;
    background-color: #000;
    border:none;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 100;
    transition: all 0.3s ease; /* smooth transition */
    width: 40%;
    margin-top: 0;
    margin-left: 397px;
    opacity: 1;

}
strong{
    color:#fff;
    font-size: 18px;
}
strong li{
    color: #fff;
}

.list-school-info{
    font-size: 14px;
    color: #fff;
}

.suggestion-list::-webkit-scrollbar{
    width: 8px;
}
.suggestion-list::-webkit-scrollbar-thumb{
    background-color: #28a745;
    border-radius: 8px;
    border: 2px solid #f0f0f0; /*Adds space around the thumb*/
}
.suggestion-list ul {
    list-style: none; /* Remove bullet points */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
    justify-content: center;
}
.enroll-btn{
    padding: 5px 10px;
    font-size: 18px;
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 35px; 
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}
.enroll-btn:hover{
    background-color: #1ab61a; 
    border: 2px solid #1ab61a;
    color: #fff; /*White text on hover*/

}

.login_btn{
    padding: 5px 10px;
    font-size: 18px;
    color: #fff;
    background-color: #1ab61a;
    border-radius: 35px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sup_button{
    display: flex;
    justify-content: space-between;
}
.enroll-btn:hover{
    background-color: #108f10;
}


/* Each item in the suggestion list */
.suggestion-list ul li {
    padding: 8px 10px; /* Reduced padding for smaller items */
    font-size: 12px; /* Smaller font size */
    color: #1ab61a;
    cursor: pointer;
    transition: background-color 0.2s ease; /* Add transition for hover effect */
    justify-content: center;
}

/* Hover effect for each suggestion item */
.suggestion-list ul li:hover {
    background-color:#000; /* Light gray for hover */
    color:white ; /* Slightly bright text for better visibility */
}

/* Adaptive Styles for Small Screens */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        padding: 10px;

    }
    .btn{
        justify-content: space-around;
    }
    
    .logo img {
        width: 200px;
    }

    .InputContainer {
        width: 90%; /* Set the width to adapt to screen size */
        max-width: 400px; /* Limit maximum size for smaller screens */
        padding-left: 10px;
    }

    .input {
        width: 100%;
    }

    .micButton {
        padding: 0px 10px;
    }
    .suggestion-list {
        width: 90%; /* Adjust width to fit mobile screen */
        left: 5%; /* Center the suggestion list */
        margin-left: 0; /* Remove desktop-specific margin */
        top: 100%; /* Position directly below the input */
        max-height: 250px; /* Adjust height for mobile */
        padding: 5px;
        min-height: 0;
    }

    .suggestion-list ul li {
        font-size: 14px; /* Slightly larger font size for readability */
        padding: 10px 15px; /* Adjust padding for touch-friendly interaction */
    }

    .suggestion-list::-webkit-scrollbar {
        width: 6px; /* Smaller scrollbar width for mobile */
    }

    .nav-buttons {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
    }

    .nav-buttons .btn {
        font-size: 14px;
        padding: 10px 20px;
        margin: 5px;
    }

    .hero-content h1 {
        font-size: 2.5rem;
        text-align: center;
    }

    .hero-content p {
        font-size: 1rem;
        
    }

    .hero-content .btn {
        padding: 10px 20px;
        font-size: 0.9rem;
    }
     /* Hide .btn2 on mobile view */
     .btn2 {
        display: none;
    }

    .hero-content{
        top: 300px;
        text-align: center;
    }

    


    
}