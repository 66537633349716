.manage_parent_add_teacher_heading_container{
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: left;

  border: 1px solid #000;
  border-radius: 10px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.manage_parent_view_edit_teacher_details_picture_section_sub_container{
    overflow: hidden;
}
.manage_parent_view_edit_teacher_details_picture_section_sub_container > div input{
    width: 100%;
}
.manage_parent_add_teacher_heading_sub_container{
  display: flex;
  gap: 4px;
  justify-content: space-between;
}

.manage_parent_add_teacher_heading_item{
  margin-top: 10px;
  margin-bottom:10px ;

  border: 1px solid #000;
  border-radius: 10px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.manage_parent_sort_by_filter_by_container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.manage_parent_sort_by_filter_by_item{
  display: flex;
/*    background-color: black;*/
/*  flex-direction: column;*/
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.manage_parent_sort_by_filter_by_forms{
  height: 10px;
  padding: 0px;
  border: none;
}
.manage_parent_sort_by_filter_by_forms > select {
  height: 26px;
  font-size: 12px;
  margin: 0px;
  padding-left: 40px;
  padding-right: 40px;
}
.manage_parent_sort_by_filter_by_forms_title{
  font-size: 15px;
  padding-top: 16px;
}

.manage_parent_table_container{
  width: 100%;
}

.manage_parent_table_item_subjects span{
  display: inline-block;
  margin: 3px;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: black;
  color: white;
}
.manage_parent_pagination_container{
  width: 100%;
}
.manage_parent_pagination_items_container{
  display: flex;
  margin-left: auto;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.manage_parent_page_items{
  background-color: #000;
  color: white;
 height: 35px;
 width: 35px;
 text-align: center;
 display: flex;
 align-items: center;
 justify-content: center;
}

/* manage parent table styles */
.manage_parent_table_container{
  border: 1px solid #000;
  border-radius: 10px;
  overflow-x: scroll;
  border-collapse: collapse;
  margin-top: 20px;
}
.manage_parent_table_container table thead th {
  background-color: #B2D4F9;
  border: 1px solid #141414;
  border-top: none;
}

.manage_parent_table_container table thead tr th:nth-child(1) {
  border-left: none;
}

.manage_parent_table_container table thead tr th:last-child {
  border-right: none;
}

.manage_parent_table_container table tbody tr td:last-child{
  border-right: none;
}
.manage_parent_table_container table tbody th,
.manage_parent_table_container table tbody tr td {
  padding: 10px;
  text-align: left;
  border: 1px solid #141414;
  border-bottom: none;
  border-left: none;
}

/* tooltip styles for the teacherManagement Component */
.manage_parent_tooltip_container {
  position: relative;
  display: inline-block;
}

.manage_parent_tooltip {
  width: 200px;
  position: absolute;
  bottom: 100%; 
  right: 10px;
  transform: translateX(-50%);
  background-color: #B2D4F9;
  padding: 10px;
  border-radius: 4px;
  white-space: wrap;
  z-index: 1000;
  transition: opacity 0.2s ease-in-out;
  font-size: 12px;
  box-shadow: -4px -4px 4px 0px #00000040;
  box-shadow: 4px 4px 4px 0px #00000040;

}

/* add teacher individual */
.manage_parent_addteacher_container{
  width: 100%;
  position: relative;
  margin: 0px;
  padding: 0px;
}
.manage_parent_view_edit_teacher_details_main_container{
  width: 100%;
  z-index: 100;
  background-color: white;
  padding: 5%;
  box-shadow: -4px -4px 4px 0px #00000040;
  box-shadow: 4px 4px 4px 0px #00000040;
  background-color: #F1F1F1;
  border-radius: 20px;
}
.manage_parent_addteacher_sub_container{
  width: 100%;
  position: absolute;
  z-index: 100;
  background-color: white;
  padding: 5%;
  box-shadow: -4px -4px 4px 0px #00000040;
  box-shadow: 4px 4px 4px 0px #00000040;
  background-color: #F1F1F1;
  border-radius: 20px;

}
.manage_parent_addteacher_hide_button{
  width: 20px;
  margin-left: auto;
  cursor: pointer;
}
.manage_parent_addteacher_sub_container_title{
  font-weight: bold ;
}
.manage_parent_addteacher_form_item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  gap: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
.manage_parent_addteacher_form_item div{
  width: 100%;
}

.manage_parent_addteacher_form_item div input{
  background-color: white;
  border-radius: 15px;
  padding-left: 20px;
  height: 40px;
  width: 100%;
  font-size: 10px;
}

.manage_parent_addteacher_form_item div label{
  display: inline-block;
  margin-bottom: 10px;
}
.manage_parent_addteacher_form_item div select{
  display: inline-block;
  margin-bottom: 10px;
  height: 40px;
  margin: 0px;
}

.manage_parent_addteacher_form_submit_button_container{
  display: flex;
  justify-content: center;
  width: 100%;
}
.manage_parent_addteacher_form_submit_button{
  border-radius: 30px;
  text-align: center;
  border: 1px solid #141414;
  width: 300px;
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
}

.manage_parent_addteacher_bulk_form_item label{
  display: inline-block;
  margin-top: 40px;
}
.manage_parent_addteacher_bulk_form_item input{
  background-color: white;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 40px;
}
.manage_parent_addteacher_bulk_sub_container_title{
    display: flex;
    justify-content: left;
    gap: 4px;
    margin-top: 40px;
}

/* Manage parent Modal Modal pop up */
.manage_parent_modal_popup_overflow_container{
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6b6b6b;
  opacity: .5;
  z-index: 100;
}

.manage_parent_modal_popup_sub_container{
  position: relative;
}
.manage_parent_modal_popup{
  position: absolute;
  z-index: 200;
  background-color: white;
  width: 100%;
  top: 50px;
  border-radius: 30px;
  padding: 20px;
  box-shadow: -4px -4px 4px 0px #00000040;
  box-shadow: 4px 4px 4px 0px #00000040;

}

/* Loader styles */
.manage_parent_addteacher_loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #88898a;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.manage_parent_modal_popup_text_icon{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* edit teacher status styles */
.manage_parent_addteacher_editstatus{
  display: flex;
  gap: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
}


.manage_parent_addteacher_editstatus_item_container > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.manage_parent_addteacher_editstatus_item_container > div input[type="checkbox"] {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.manage_parent_addteacher_editstatus_item_container > div:nth-child(1) label {
    color:#39B54A;
    font-weight: bold;
}

.manage_parent_addteacher_editstatus_item_container > div:nth-child(2) label {
  font-weight: bold;
}

.manage_parent_addteacher_editstatus_item_container{
  display: flex;
  justify-content: space-around;
  margin-top: 4px;
  margin-bottom: 10px;
}
/* delete teacher styles */
.manage_parent_delete_teacher_item_container{
    margin-top: 20px;
    margin-bottom: 20px;
}
.manage_parent_delete_teacher_item_container > div input {
  background-color: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}


.manage_parent_deletez_teacher_form_submit_button_container{
  display: flex;
  justify-content: center;
  width: 100%;
}

.manage_parent_view_edit_teacher_details_submit_button{
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  border: 1px solid black;
  width: 300px;
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
}
.manage_parent_delete_teacher_form_submit_button{
  border-radius: 30px;
  cursor: pointer;
  color: #FF0000;
  text-align: center;
  border: 1px solid #FF0000;
  width: 300px;
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
}

/* view edit details for a teacher styles */
.manage_parent_view_edit_teacher_details_container{
  margin-top: 30px;
}
.manage_parent_view_edit_teacher_details_picture_section_container{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.manage_parent_view_edit_teacher_details_picture_section_container > div {
/*  width: 50%;*/
}

.manage_parent_view_edit_teacher_details_picture_section_sub_container{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.manage_parent_view_edit_teacher_details_picture_section_sub_container div:nth-child(1){
  width: 100px;
  height: 100px;
  margin: auto;
  
}
.manage_parent_view_edit_teacher_details_picture_section_sub_container div:nth-child(1) img{
  display: flex;
  border: 1px solid;
  border-radius: 50%;
  padding: 10px;
}
.manage_parent_view_edit_teacher_details_picture_section_sub_container div:nth-child(2) input{
  background-color: white;
  padding-top: 10px;
  padding-left: 10px;
  border-radius: 8px;
}

.manage_parent_view_edit_teacher_details_picture_section_sub_container div:nth-child(2) label{
  text-align: center;
  display: inline-block;
  width: 100%;
}
.manage_parent_view_edit_teacher_details_picture_section_container > div:nth-child(2) input{
  background-color: white;
  border-radius: 8px;
  padding-left: 10px;
}
.manage_parent_view_edit_teacher_details_form_item {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 25px;
}
.manage_parent_view_edit_teacher_details_form_item > div {
/*  width: 50%;*/
}
.manage_parent_view_edit_teacher_details_form_item > div input {
  background-color: white;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}

@media only screen and (min-width: 760px) {
  .manage_parent_add_teacher_heading_container{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #000;
    border-radius: 10px;
    width: 100%;
    height: 70px;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }

  .manage_parent_addteacher_form_item{
    flex-direction: row;
    justify-content: space-between;
  }
  .manage_parent_addteacher_bulk_sub_container_title{
    width: 35%;
  }
  .manage_parent_addteacher_form_item div input{
    font-size: unset;
  }
  .manage_parent_sort_by_filter_by_item{
    flex-direction: row;
  }
  .manage_parent_sort_by_filter_by_container{
   flex-direction: row;
  }
  .manage_parent_pagination_items_container{
    width: 50%;
  }
  .manage_parent_delete_teacher_item_container > div input {
      width: 50%;
  }
  .manage_parent_view_edit_teacher_details_picture_section_container{
      flex-direction: row;
  }
  .manage_parent_view_edit_teacher_details_picture_section_sub_container{
     flex-direction: row;
 }
 .manage_parent_view_edit_teacher_details_picture_section_container > div {
    width: 50%;
  }
  .manage_parent_view_edit_teacher_details_picture_section_sub_container div:nth-child(1){
     margin: unset;
   }

   .manage_parent_view_edit_teacher_details_picture_section_sub_container div:nth-child(2) label{
      text-align: unset;
   }
   .manage_parent_view_edit_teacher_details_picture_section_container{
     gap: 75px;
  }
  .manage_parent_view_edit_teacher_details_form_item {
    flex-direction: row;
  }
  .manage_parent_view_edit_teacher_details_form_item > div {
      width: 50%;
   }

   .manage_parent_view_edit_teacher_details_form_item {
      gap: 75px;
    }
}