.total_stats1 {
    display: flex;
    width: 400px;
    min-width: 300px;
    max-width: 400px;
    border-radius: 15px; 
    color: #000;
    background-color: #fff;
    padding: 5px;
  }
  
  .total_stats1__left {
    display: flex;
    width: 400px;
    min-width: 300px;
    max-width: 400px;
    height: 100%; 
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20px;
    padding-top: 20px;
    gap: 15px;
  }
  
  .total_stats1__left span {
    display: flex;
    width: 100%;
    height: 18px; 
    font-size: 16px;
    font-weight: 500;
    color: #000; 
    align-items: center;
    padding-inline: 15px;
  }
  
  .total_stats1__left span:nth-of-type(1) {
    font-size: 25px;
  }
  
  .total_stats1__right {
    display: flex;
    width: 50px;
    height: 100%; 
  }
  
  @media (max-width: 750px) {
    .total_stats1 {
      display: flex;
      width: 100%;
      min-height: 100px; 
      flex-wrap: wrap;
    }
    
    .total_stats1__left {
      display: flex;
      width: calc(100% - 50px);
      height: 100%;  
      padding-bottom: 12px; 
      gap: 15px;
    }
    
    .total_stats1__left span {
      display: flex;
      width: 100%;
      height: 18px; 
      font-size: 15px; 
    }
    
    .total_stats1__left span:nth-of-type(1) {
      font-size: 20px;
    }
    
    .total_stats1__right {
      display: flex;
      width: 50px;
      height: 100%;  
    }
  }

  .widgetImg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  
  .subjectInfo {
    border: 1px solid #000;
    padding-inline: 15px;
    border-radius: 5px;
    padding: 10px;
  }

  .subjectInfo1 {
    border: 1px solid #000;
    padding-inline: 15px;
    border-radius: 5px;
    padding: 10px;
    height: 150px;
  }

  .pHeading {
    font-size: 12px;
  }
  
  .subjectList{
    margin-right: 15px;
    padding-inline: 15px;
    font-size: 12px;
    background-color: #000;
    padding: 2px;
    color: #fff;
    border-radius: 5px;
  }
  
  .subjectList1{
    margin-right: 15px;
    width: 100px;
    padding-inline: 15px;
    font-size: 12px;
    background-color: #000;
    padding: 2px;
    color: #fff;
    border-radius: 5px;
    min-width: 100px;
    margin-top: 60px;
  }

  .pHeaderIcon{
    padding-bottom: 10px;
    
  }

  .viewMoreSubjects{
    margin-right: 15px;
    padding-inline: 15px;
    font-size: 12px;
    padding-top: 20px;
  }

  .reportCard{
    margin-right: 20px;
    padding-inline: 20px;
    border: 1px solid #000;
    border-radius: 35px;
    padding: 10px;
    text-align: center;
    width: 100%;
    font-size: 12px;

  }

  .reportCard1{
    margin-right: 20px;
    padding-inline: 20px;
    border-radius: 35px;
    padding: 10px;
    text-align: center;
    width: 100%;
    background-color: #39B54A;
    color: #fff;

  }




  /* For another card */
  .total_stats2 {
    display: flex;
    width: 100%;
    min-width: 300px;
    border-radius: 15px; 
    color: #000;
    background-color: #fff;
    padding: 5px;
  }
  
  .total_stats2__left {
    display: flex;
    width: 100%;
    min-width: 300px;
    height: 100%; 
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20px;
    padding-top: 20px;
    gap: 15px;
  }
  
  .total_stats2__left span {
    display: flex;
    width: 100%;
    height: 18px; 
    font-size: 16px;
    font-weight: 500;
    color: #000; 
    align-items: center;
    padding-inline: 15px;
  }
  
  .total_stats2__left span:nth-of-type(1) {
    font-size: 25px;
  }
  
  .total_stats2__right {
    display: flex;
    width: 50px;
    height: 100%; 
  }
  
  @media (max-width: 750px) {
    .total_stats2 {
      display: flex;
      width: 100%;
      min-height: 100px; 
      flex-wrap: wrap;
    }
    
    .total_stats2__left {
      display: flex;
      width: calc(100% - 50px);
      height: 100%;  
      padding-bottom: 12px; 
      gap: 15px;
    }
    
    .total_stats2__left span {
      display: flex;
      width: 100%;
      height: 18px; 
      font-size: 15px; 
    }
    
    .total_stats2__left span:nth-of-type(1) {
      font-size: 20px;
    }
    
    .total_stats2__right {
      display: flex;
      width: 50px;
      height: 100%;  
    }
  }

  .widgetImg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  
  .subjectInfo {
    border: 1px solid #000;
    padding-inline: 15px;
    border-radius: 5px;
    padding: 10px;
  }

  .pHeading {
    font-size: 12px;
  }
  
  .subjectList{
    margin-right: 15px;
    padding-inline: 15px;
    font-size: 12px;
    background-color: #000;
    padding: 2px;
    color: #fff;
    border-radius: 5px;
  }

  .subjectList1{
    margin-right: 15px;
    width: 100px;
    padding-inline: 15px;
    font-size: 12px;
    background-color: #000;
    padding: 2px;
    color: #fff;
    border-radius: 5px;
    min-width: 100px;
  }

  .pHeaderIcon{
    padding-bottom: 10px;
    
  }

  .viewMoreSubjects{
    margin-right: 15px;
    padding-inline: 15px;
    font-size: 12px;
    padding-top: 20px;
  }

  .reportCard{
    margin-right: 20px;
    padding-inline: 20px;
    border: 1px solid #000;
    border-radius: 35px;
    padding: 10px;
    text-align: center;
    width: 100%;
    font-size: 12px;

  }

  .reportCard1{
    margin-right: 20px;
    padding-inline: 20px;
    border-radius: 35px;
    padding: 10px;
    text-align: center;
    width: 100%;
    background-color: #39B54A;
    color: #fff;

  }