.landing_page {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  width: 100%;
  
  background-size: 100% auto;
  background-repeat: no-repeat repeat;
}
.testimonials {
  display: flex;
  width: 100;
  flex-grow: 1;
  height: 300px; 
}

.get_in_touch {
  display: flex;
  width: 300px;
  flex-grow: 1;
  height: fit-content; 
  background-image: url("../../public/images/GET\ IN\ TOUCH.png");
  background-repeat: no-repeat;
  background-size: 300px auto;
  background-position: calc(100% - 50px) center;
  background-color: #E9E9E9;
  padding-inline: 30px;
  padding-block: 30px;
  flex-direction: column;
  gap: 30px;
}

.get_in_touch__txt {
  font-weight: bold;
}

.get_in_touch a {
  display: flex;
  width: fit-content;
  height: 50px;
  border-radius: 25px;
  color: #fff;
  padding-inline: 30px;
  align-items: center;
  background-color: #39B54A;
}

.get_in_touch a:hover {
  background-color: #000;
}

.toggle_enquiry_form_container {
  display: flex;
  width: 100%;
  height: 100px; 
  background-color: #B2D4F9;
  padding-inline: 30px;
  align-items: center;
}

.toggle_enquiry_form {
  display: flex;
  width: fit-content;
  height: 50px;
  border-radius: 25px;
  align-items: center;
  border: 1px solid #000;
  background-color: transparent;
  padding-inline: 20px;
  justify-content: center;
  gap: 10px;
}

.toggle_enquiry_form:hover {
  background-color: #000;
  color: #fff;
}

.toggle_enquiry_form_container__hidden_or_shown {
  display: flex;
  height: 100%;
  align-items: center; 
  font-size: 25px;
}

.enquiry_form_container {
  display: flex;
  width: 100%;
}

.enquiry_form_container h3 {
  font-weight: bold;
  font-size: 16px;
}

.enquiry_form {
  display: none;
  flex-wrap: wrap;
  width: 100%;
  background-color: #B2D4F9;
  gap: 30px;
  padding-inline: 30px;
  padding-block: 10px 50px;
}

.enquiry_form h3 {
  display: flex;
  width: 100%;
}


.submit_enquiry_form {
  display: flex;
  width: 200px;
  margin-inline: calc(50% - 100px);
}

.landing_page .enquiry_form .form_input_container {
  width: 400px !important;
  min-height: 90px; 
  flex-grow: 1; 
  justify-content: flex-start;
  gap: 10px;  
}

.landing_page .enquiry_form select {
  width: 100%;
}

.enquiry_form select {
  width: 100%;
  height: 50px;
}

.province_container, .district_container {
  display: flex; 
  width: 400px;
  flex-grow: 1;
  position: relative;
}

/* .province_container select, .province_container input[type="text"] {
  height: 50px;
} */

.province_error, .district_error {
  display: none;
  min-width: 200px;
  min-height: 50px;
  justify-content: space-between;
  align-items: center;
  background-color: #B2D4F9;
  position: absolute;
  top: 30px;
  left: 5px;
  z-index: 2;
  font-size: 14px;
  box-shadow: 0 10px 10px #00000027, 10px 0 10px #00000027, -10px 0 10px #00000027;
  padding-inline: 5px;
  border-radius: 5px;
}

.close_parent {
  display: flex;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  color: red;
  border-radius: 15px;
  justify-content: center;
  align-items: flex-end;
  font-size: 30px;
  cursor: pointer;
  /* background-color: grey; */
}

.testimonials {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: #f1f1f1;
  padding-inline: 30px;
  padding-block: 40px;  
  background-image: url("../../public/images/quotation_marks.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
}

.testimonials h3 {
  max-width: 500px;
  margin-inline: auto;
  font-weight: bold;
  /* border: 1px solid green; */
}

.testimonials_data {
  display: flex; 
  width: 100%; 
  max-width: 500px;
  /* background-color: blue; */
  height: 200px;
  align-items: flex-start; 
  overflow: hidden;
  flex-wrap: wrap;
  /* padding: 10px 0; */
}

.testimonial_slider {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%; 
}

.sliders {
  display: flex;
  width: 100%;
  height: 30px; 
  margin-block: 20px 0;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.slider {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #000;
}

.current_slider {
  background-color: #000;
}

.testimonial_slider__top {
  display: flex;
  width: 100%;
  min-height: 180px;
  padding-inline: 0 30px;
  padding-block: 20px 40px;
  gap: 20px;
  align-items: flex-start;
  /* border: 1px solid red; */
}

.testimonial_slider__top img {
  width: 100px;
  height: auto;
  object-fit: contain;  
}

.testimonial_slider__top p, .testifier {
  font-style: italic;
  font-size: 14px;
}

.testimonial_slider__bottom {
  display: flex;
  width: 100%;
  height: 20px;
  /* border: 1px solid red; */
  align-items: center;
  gap: 20px; 
  justify-content: flex-start;
}

.testimonial_slider__bottom span {
  display: flex;
  width: fit-content;
  /* background: green; */
}

.testimonial_slider__bottom .black_placeholder {   
  display: flex;
  width: 100px;
  height: 3px;
  background-color: #000;
}

.modules {
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-wrap: wrap;
  padding-inline: 30px;
  padding-block: 30px; 
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.modules_list__container {
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-wrap: wrap;
  padding-inline: 30px; 
  gap: 30px;
  align-items: center; 
}

.modules_list {
  display: flex;
  width: 300px;
  height: 300px;
  flex-grow: 1;
  border-radius: 10px;
  flex-direction: column;
  padding-inline: 30px;
  padding-block: 30px;
  background-size: auto 70%;
  background-position: center;
  background-repeat: no-repeat;
}

.modules_list h3 {
  font-size: 18px;
  color: #39B54A;
  font-weight: bold;
  margin-block: 0 10px;
}

.modules_list h4 {
  font-size: 16px;
  color: #6C6C6C;
  font-weight: bold;
  margin-block: 0 10px;
}

.modules_list p {
  font-size: 14px; 
  margin-block: 0 10px;
}

.many_more {
  display: flex; 
  font-weight: bold;
  width: 100%;
  padding-inline: 30px;
}
 
.modules_list__student_info {
  background-image: url("../../public/images/student_info.png");
  background-color: #f1f1f1;
}

.modules_list__institution_admin {
  background-image: url("../../public/images/institution_admin.png");
  background-color: #B2D4F9;
}

.modules_list__student_performance {
  background-image: url("../../public/images/student_performance.png");
  background-color: #B2D4F9;
}

.modules_list__communication {
  background-image: url("../../public/images/communication.png");
  background-color: #f1f1f1;
}

.modules h2 {
  font-weight: bold;
  font-size: 18px;
}

.modules__desc {
  max-width: 600px; 
  padding-inline: 30px;
  flex-grow: 1;
}

.unlock_comprehensive {
  display: flex;
  width: 100%;
  background-color: #f1f1f1;
  flex-direction: column;
}

.unlock_comprehensive div {
  display: flex;
  width: 100%;
  min-height: 100px;
  padding-inline: 30px;
  padding-block: 30px;
  background-size: auto 40px; 
  background-repeat: no-repeat;
}

.unlock_comprehensive__top {
  background-position: calc(100% - 30px) center;
  background-image: url("../../public/images/unlock.png");
}

.unlock_comprehensive__bottom {
  justify-content: flex-end;
  background-position: 30px center;
  background-image: url("../../public/images/software.png");
}

.unlock_comprehensive span {
  width: 50%;
  font-weight: 500;
} 

.empowering {
  display: flex;
  width: 100%;
  min-height: 200px;
  background-color: #2b2b2b;
  align-items: center;
  color: #fff;
  font-size: 20px;
  padding-inline: 50px;
  padding-block: 30px;
  background-image: url("../../public/images/school_children.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.our_valued {
  display: flex;
  flex-direction: column;
  width: 100%; 
  background-color: #fff;
  align-items: center;
  height: fit-content;
  padding-inline: 30px;
  padding-block: 30px;
}

.our_valued h2 {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.our_valued__clients {
  display: flex;
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-inline: 30px;
  padding-block: 20px; 
  gap: 20px; 
}

.some_of_our_clients {
  display: flex;
  width: 50px;
  height: 100%;
  flex-wrap: wrap;
  align-items: center;
  font-size: 18px;
}

.placeholder__vertical {
  display: flex;
  width: 2px;
  height: calc(100% - 10px);
  background-color: #000;  
}

.our_valued__clients_cont {
  display: flex;
  width: fit-content;
  max-width: calc(100% - 100px); 
  height: 100%; 
  gap: 20px;
}
 
.our_valued__clients__cont__div {
  display: flex;
  width: 300px;
  background-color: #f1f1f1;
  padding-inline: 10px;
  padding-block: 5px;
  height: 100%;
  font-size: 15px;
  gap: 5px;
}

.our_valued__clients__cont__div__left {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.our_valued__clients_cont img {
  display: flex;
  align-items: flex-start;
  width: 80px;
  object-fit: contain; 
}

.features {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-inline: 30px;
  padding-block: 20px;
  gap: 20px;
}

.features h2 {
  font-size: 18px;
  font-weight: bold;
}

.features h3 {
  font-size: 16px;
  font-weight: bold;
}

.software_features {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.software_features__feature {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 150px;
  flex-grow: 1; 
  gap: 10px;
}

.software_features__feature p {
  font-size: 14px;
}

.why_choose {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  color: #fff;
  background-color: #000;
  padding-inline: 30px;
  padding-block: 30px;
  align-items: center;
}

.why_choose h2 {
  display: flex;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  justify-content: center;
}

.why_choose__options {
  display: flex;
  width: 100%;
  gap: 15px;
  justify-content: center;
}

.why_choose__options__option {
  display: flex;
  width: fit-content;
  padding-inline: 20px;
  height: 40px;
  align-items: center;
  background-color: #1b1b1b;
  font-size: 14px;
}

.who_we_are {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  padding-inline: 30px;
  padding-block: 30px;
  gap: 10px;
  background-color: #fff;
}

.who_we_are h2 {
  font-size: 18px;
  font-weight: bold;
}

.who_we_are p {
  font-size: 16px;
}

.hero_section {
  display: flex;
  width: 100%;
  height: 350px;
  padding-inline: 30px;
  padding-block: 50px 30px;
  gap: 50px; 
}

.hero_section__left, .hero_section__right {
  display: flex;
  position: relative;
  width: calc(50% - 60px);
  flex-direction: column;
  height: 100%;
  border-radius: 20px;
  padding-inline: 25px;
  padding-block: 20px;
  justify-content: center;
}

.hero_section__left {
  background-color: #000;
}

.hero_section__left h1 {
  color: #fff;
  font-size: 35px;
  font-weight: bold;
}

.hero_section__left span {
  display: flex;
  width: 60px;
  height: 60px;
  background-color: #d9d9d9;
  border-radius: 30px;
  position: absolute;
  top: -20px;
  right: -20px;
}

.hero_section__right {
  background-color: #fff;
  gap: 20px;
  padding-bottom: 0;
}

.hero_section__right p {
  display: flex;
  font-size: 25px;
  font-weight: bold;
}

.hero_section__right a {
  display: flex;
  width: fit-content;
  height: 50px;
  border-radius: 25px;
  background-color: #39B54A;
  color: #fff;
  align-items: center;
  font-size: 15px;
  padding-inline: 25px;
}

.hero_section__right a:hover {
  background-color: #000;
}

.hero_section__right span {
  display: flex;
  width: 60px;
  height: 60px;
  background-color: #000;
  border-radius: 30px;
  position: absolute;
  bottom: -20px;
  right: -20px;
}

@media (max-width: 750px) { 
  .get_in_touch {
    display: flex;
    width: 300px;
    flex-grow: 1;
    height: fit-content; 
    background-image: url("../../public/images/GET\ IN\ TOUCH.png");
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: calc(100% - 30px) calc(100% - 30px);
    background-color: #E9E9E9;
    padding-inline: 30px;
    padding-block: 30px;
    flex-direction: column;
    gap: 30px;
  }

  .testimonials {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1; 
    background-color: #f1f1f1;
    padding-inline: 30px;
    padding-block: 40px;  
    background-image: url("../../public/images/quotation_marks.png");
    background-size: 70% auto;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    height: fit-content;
  }
  
  .testimonials h3 {
    max-width: 100%; 
  }
  
  .testimonials_data {
    display: flex; 
    width: 100%; 
    max-width: 100%; 
    min-height: 350px; 
    align-items: flex-start; 
    overflow: hidden;
    flex-wrap: wrap;  
  }
  
  .testimonial_slider {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%; 
  }

  .testimonial_slider__bottom {
    display: flex;
    width: 100%;
    height: fit-content; 
    align-items: center;
    gap: 20px; 
    flex-wrap: wrap; 
  }

  .testimonial_slider__bottom .black_placeholder {   
    display: flex;
    width: 100px;
    margin-inline: 0 calc(100% - 100px);
    height: 3px;
    background-color: #000;
  }
 
  .testifier {
    width: 100%;
    height: fit-content; 
  }
  .testimonial_slider__top {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: fit-content;
    padding-inline: 0 30px;
    padding-block: 20px;
    gap: 20px;
    align-items: flex-start; 
  }
  
  .testimonial_slider__top img {
    width: 100px;
    height: auto;
    object-fit: contain;  
  }
   
  .many_more { 
    padding-inline: 0;
  }
  .modules_list__container { 
    padding-inline: 0; 
    gap: 30px; 
  }
  
  .modules_list {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-grow: 1; 
  }
   
  .modules_list__student_info { 
    background-color: #f1f1f1;
  }
  
  .modules_list__institution_admin { 
    background-color: #B2D4F9;
  }
  
  .modules_list__student_performance { 
    background-color: #f1f1f1;
  }
  
  .modules_list__communication { 
    background-color: #B2D4F9;
  }

  .modules__desc {
    max-width: 100%; 
    padding-inline: 0;
    text-align: center;
    flex-grow: 1; 
  }
  
  .unlock_comprehensive {
    padding-block: 0 30px;
  }
  
  .unlock_comprehensive div {
    display: flex;
    width: 100%;
    min-height: fit-content;
    padding-inline: 30px;
    padding-block: 30px 50px;
    background-size: auto 40px; 
    background-repeat: no-repeat;
  }
  
  .unlock_comprehensive__top {
    background-position: 30px 100%;
    background-image: url("../../public/images/unlock.png");
  }
  
  .unlock_comprehensive__bottom {
    justify-content: flex-end;
    background-position: 30px 100%;
    background-image: url("../../public/images/software.png");
  }
  
  .unlock_comprehensive span {
    width: 100%;
    font-weight: 500;
    margin-block: 0 10px;
  } 
  
  .empowering { 
    font-size: 18px;
    min-height: fit-content;
    padding-inline: 30px; 
    padding-block: 50px; 
  } 
  
  .our_valued__clients {
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-inline: 0;   
  }
  
  .some_of_our_clients {
    display: flex;
    width: 100%;
    height: fit-content;    
  }
  
  .placeholder__vertical { 
    height: 2px;
    width: calc(100% - 50px); 
  }
  
  .our_valued__clients_cont {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 100%; 
    height: 100%; 
    gap: 20px; 
  }
   
  .our_valued__clients__cont__div {
    display: flex;
    width: 100%;
    background-color: #f1f1f1;
    justify-content: space-between;
    padding-inline: 30px;
    padding-block: 20px;
    height: 100%;
    font-size: 15px;
    gap: 5px; 
  }
     
  .software_features__feature {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    flex-grow: 1; 
    gap: 10px;
    padding-block: 30px 0; 
  }
   
  .why_choose__options {
   flex-wrap: wrap;
  }
   
  .hero_section {
    display: flex;
    width: 100%;
    height: fit-content;
    padding-inline: 20px;
    padding-block: 30px;
    gap: 40px; 
    flex-direction: column; 
  }
  
  .hero_section__left, .hero_section__right { 
    width: 100%; 
    min-height: 200px; 
  }
   
  .hero_section__left h1 { 
    font-size: 30px; 
  }
  
  .hero_section__left span { 
    width: 40px;
    height: 40px; 
    border-radius: 20px; 
    top: -15px;
    right: -15px;
  } 
  
  .hero_section__right p { 
    font-size: 20px; 
  }

  .hero_section__right span { 
    width: 40px;
    height: 40px; 
    border-radius: 20px; 
    bottom: -15px;
    right: -15px;
  }
  .province_container, .district_container {
    width: 100%;
  }
}

