body {
  background-image: url("../../public/images/bg.png");
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-attachment: fixed;
}
 
.dynamic_profile_content, .dynamic_profile_content__data_container {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  border: 1px solid #000; 
}

.left_nav_and_main_content_container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-block: 30px 0;
}

.dynamic_profile_content {
  width: calc(100% - 250px);
  height: fit-content;
  border-radius: 15px;
  gap: 20px;
  flex-direction: column;
  margin-right: 30px;
}

.dynamic_profile_content__data_container {
  min-height: 50px;
  border-radius: 15px;
  padding-inline: 10px;
  flex-wrap: wrap;
  word-break: break-all; 
}

.dynamic_profile_content h1 {
  display: flex;
  width: 200px;
  height: 50px;
  background-color: #000;
  color: #fff;
  font-size: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-inline: auto;
  justify-content: center;
  align-items: center;
}

.dynamic_profile_content__container {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center; 
  padding-inline: 20px;
  padding-block: 20px 30px;
}

.profile_pic_container {
  display: flex;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 1px solid #000;
  overflow: hidden;
}

.profile_pic_container img {
  width: 100%; 
  object-fit: cover;
}

@media (max-width: 750px) { 
  .dynamic_profile_content {
    width: calc(100% - 70px); 
    margin-right: 10px;
  }
  
  .dynamic_profile_content__data_container {
    min-height: 40px;
    border-radius: 10px; 
    padding-block: 10px;
  }
  
  .dynamic_profile_content h1 { 
    width: 180px;
    height: 40px; 
    font-size: 18px; 
  }
  
  .dynamic_profile_content__container {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center; 
    padding-inline: 20px;
    padding-block: 20px 30px;
  } 
}
