.left_side_nav a.active {
    background-color: white;
    color: black; /* Adjust text color for visibility on white background */
  }
  
  .left_side_nav a {
    padding: 10px;
    display: block;
    text-decoration: none;
    color: inherit; /* Keeps the text color the same unless overridden */
  }
  
  .left_side_nav a:hover {
    background-color: #f0f0f0; /* Example hover color */
  }
  
  /* To ensure other styles don’t affect the active state */
  .left_side_nav a.active:hover {
    background-color: white;
  }
  