.teacherportalnone{
  display: none !important;
}

.teacherportalblock{
  display: block !important;
}

.teacher-portal-tabs-container  {
    width: 100%;
}

.teacher-portal-tabs-container{
    width: 100%;
}
.teacher-portal-tab > div {
    display: flex;
    padding: 20px;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    background-color: white;
}

.teacher-portal-tab > div img {
    width: 50px;
    margin: auto;
    /* background-color: black; */
    font-weight: 600;
    text-align: center;
}
.teacher-portal-title{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.teacher-portal-tab{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 40px;
}
.teacher-portal-tab > div > div{
    margin-bottom: 4p2x;
}
.teacher-portal-tab > div > div:nth-child(2){
    border-bottom: solid 3px black; 
}

.teacher-portal-course-schedule{
    background-color: white;
    box-shadow: 0px 4px 4px 0px #00000040;

}
@media only screen and (min-width: 760px) {
    .teacher-portal-tab{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
}


/* class management styles----------------------------- */
.teacher-portal-table-container {
    width: 100%;
    max-height: 300px; /* Set a max height for scrolling */
    overflow-y: auto;  /* Enable vertical scrolling */
    border: 1px solid #ccc; /* Optional: Border around the scrollable area */
}
.teacher-portal-table-container table {
    width: 100%;
    border-collapse: collapse;
    background-color: white; /* Table background */
}
.teacher-portal-table-container th, .teacher-portal-table-container td {
    border: 1px solid #ccc; /* Cell borders */
    padding: 10px;
    text-align: center;
}
.teacher-portal-table-container th {
    background-color: #f2f2f2; /* Header background */
}
.teacher-portal-table-container td:nth-child(2) {
    width: 40%; 
    background-color: black; 
    color: white; 
}
/* Remove outer border */
.teacher-portal-table-container table {
    border: none;
}
@media (max-width: 600px) {
    .teacher-portal-table-container th, .teacher-portal-table-container td {
        font-size: 14px;
    }
}

/* teacherPortalStudentList  styles */
.teacher-portal-student-classlist{
     background-color: white;
     border-radius: 25px;
     overflow: hidden;
     padding: 20px;
}

.teacher-portal-student-list-container{
    display: none;
    background-color: rgba(100,100,100, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    border-radius: 25px;
}
.teacher-portal-student-classlist-container{
    opacity: 1;        
    position: absolute;
    width: 78%;
    left: 0;
    right: 0;
    top: 10%;
    margin: auto;
}
.teacher-portal-student-list{
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
}
.teacher-portal-student-list div:nth-child(1){
    font-weight: 600;
}
.teacher-portal-student-list div:nth-child(2){
    cursor: pointer;
}





.teacher_portal_pagination_container{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 101PX;
  }
  .teacher_portal_pagination_items_container{
    display: flex;
    margin-left: auto;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }
.teacher_portal_page_items{
    background-color: #000;
    color: white;
   height: 35px;
   width: 35px;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
  }

  .teacher-portal-student-profile div:nth-child(1){
    font-weight: 600;
  }
  .teacher-portal-student-profile div:nth-child(2){
    cursor: pointer;
  }

  .teacher-portal-student-profile{
    display: flex;
    justify-content: space-between;
  }

  .teacher-portal-student-profile-main-container{
    position: absolute;
    top: 10%;
    background-color: white;
    width: 78%;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 25px;
    padding: 25px;
  }

  .teacher-portal-student-profile-content{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .teacher-portal-student-profile-content div{
    background-color: black;
    color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 15px;
  }

  .teacher-portal-filters-container{
    display: flex;
    width: 100%;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
  }

  .teacher-portal-filters{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .teacher-portal-filters-container > div:nth-child(2){
    border-radius: 25px;
    border: 1px black solid;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
  }
  @media only screen and (min-width: 760px) {
    .teacher-portal-filters-container{
        width: 70%;
        font-size: unset;
      }
    
  }

  /* -------------------------------- */
  .teacher-portal-exam-creation-button-tab{
    display: flex;
    justify-content: space-around;
    font-size: 12px;
    width: 100%;
    box-shadow: 0px 4px 4px 0px #00000040;
    background-color: white;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 25px
  }

  .teacher-portal-exam-creation-button-tab div:nth-child(2){
    background-color: #38B349;
    color: white;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 25px;
    cursor: pointer
  }

.teacher-portal-table-title{
    font-weight: 600;
    width: 100%;
}

  @media only screen and (min-width: 760px) {
    .teacher-portal-exam-creation-button-tab{
        display: flex;
        gap: 50px;
        font-size: unset;
        width: 100%;
        box-shadow: 0px 4px 4px 0px #00000040;
        background-color: white;
        padding: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 25px
      }
    
  }

  /* -------------------exam management----------------------- */
  .teacher-portal-exam-management-container{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
  }

  .teacher-portal-exam-management-container > div form > div {
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
  }
  .teacher-portal-exam-management-container > div form > div input {
     background-color: #D9D9D9;
     width: 100%;
     border-radius: 15px;
     padding-left: 20px;
     padding-right: 20px;
  }
  .teacher-portal-plus-icon{
    border: 1px solid black;
    width: 100%;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    cursor: pointer;
  }
  .teacher-portal-form-submit-container{
    width: 100%;
    margin-top: 10px;
  }
  .teacher-portal-form-submit{
    background-color: #39B54A;
    color: white;
    border-radius: 25px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: auto;
  }

  @media only screen and (min-width: 760px) {
    .teacher-portal-exam-management-container > div form > div input {
        background-color: #D9D9D9;
        border-radius: 15px;
        padding-left: 20px;
        padding-right: 20px;
     }
     .teacher-portal-exam-management-container > div form > div {
        border-radius: 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }

      .teacher-portal-form-submit-container{
        width: 100%;
        margin-top: 30px;
      }
  }

  /* add paper styles */
  .teacher-portal-add-paper-container{
      background-color: black;
      height: 300px;
      width: 100%;
      position: absolute;
      z-index: 200;
  }

  /* teacher-portal-grading-results -----------------------------------------*/
  .teacher-portal-grading-results{
    display: flex;
    gap: 20px;
  }

  .teacher-portal-grading-results div:nth-child(1){
    font-weight: bold;
  }

  .teacher-portal-grading-results div:nth-child(3){
    border: 1px solid black;
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
  }

  .teacher-portal-total-marks div:nth-child(1){
    font-weight: bold;
  }

  .teacher-portal-total-marks{
    display: flex;
    gap: 20px;
  }


  .teacher-portal-grading-results-main-container{
    position: absolute;
    top: 10%;
    background-color: white;
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 25px;
    padding: 25px;
    overflow-x: auto; 
  }

 @media only screen and (min-width: 760px) {
  .teacher-portal-grading-results-main-container{
    position: absolute;
    top: 10%;
    background-color: white;
    width: 78%;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 25px;
    padding: 25px;
  }
 }


.teacher-portal-exam-grading-container > div:nth-child(1) form > table {
  width: 100%;
  border-collapse: collapse;
}
.teacher-portal-exam-grading-container > div:nth-child(1) form > table th, 
.teacher-portal-exam-grading-container > div:nth-child(1) form > table td {
  border: 1px solid #ccc;
  text-align: center;
}
.teacher-portal-exam-grading-container > div:nth-child(1) form > table th {
  background-color: #f4f4f4;
}

/* settings----------------------------- */
.teacher-portal-settings-title{
  font-weight: bold;
}


.teacher-portal-tabs-container form {
  max-width: 400px; /* Limit form width */
  margin: auto; /* Center the form */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}
.teacher-portal-tabs-container label {
  display: block; 
  margin-bottom: 5px;
}
.teacher-portal-tabs-container  input[type="password"],
.teacher-portal-tabs-container  input[type="submit"] {
  width: 100%; 
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.teacher-portal-tabs-container  input[type="submit"] {
  background-color: #4CAF50; 
  color: white;
  cursor: pointer;
}
.teacher-portal-tabs-container  input[type="submit"]:hover {
  background-color: #45a049;
}