.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Black overlay with transparency */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Make sure it's on top of everything */
}

.loading-spinner {
    color: white;
}
