.admin_styles { 
  margin-inline: auto;
  background-image: url('../../public//images/bg.png');
  background-size: 100% auto;
  background-position: center 0;
  background-repeat: no-repeat repeat;
  background-color: #fff;
}

.admin {
  display: flex; 
  width: 100%; 
  height: fit-content;
  margin-inline: auto;
  flex-direction: column;
  position: relative;
}

.admin .container {
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-top: 30px; 
  height: fit-content;
  justify-content: space-between;  
  padding-right: 30px;  
}

.admin .content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 190px);
  height: fit-content; 
  align-items: center;
  gap: 50px;
}

.search_container {
  display: flex;
  width: 600px;
  max-width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: #fff;
  overflow: hidden;
  padding-left: 55px;
  background-image: url("../../public/images/search.png");
  background-size: auto 50%;
  background-position: 20px center;
  background-repeat: no-repeat;
}

.search_container input {
  display: flex;
  width: 500px;
  max-width: calc(100% - 60px);
  height: 100%;
  padding-inline: 0 30px;
  background-color: transparent;
  outline: none;
}

.about_school, .manage_your_school {
  display: flex;
  width: 100%;
  border: 1px solid #000;
  border-radius: 30px;
  padding-inline: 30px;
  padding-block: 0 50px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.about_school h1, .manage_your_school h2  {
  display: flex;
  width: fit-content;
  padding-inline: 20px;
  background-color: #000;
  color: #fff;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 20px;
  margin-inline: auto;
}

.about_school h3 {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  justify-content: flex-start;
}

.about_school .info, .manage_your_school .info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content; 
  gap: 20px;
  justify-content: center;
}

.about_school .school_address {
  display: flex;
  width: 230px;
  height: 100px;
  border: 1px solid #000;
  border-radius: 15px;
  padding-inline: 20px;
  padding-block: 20px;
  background-image: url("../../public/images/location.png");
  background-size: 30px auto;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) calc(100% - 10px);
  font-size: 15px;
}
 
.total_students_and_teachers {
  display: flex;
  width: 230px;
  flex-direction: column;
  justify-content: space-between;
}

.manage_your_school {
  margin-bottom: 50px;
}

@media (max-width: 760px) {
  .admin .content { 
    width: calc(100% - 60px); 
    gap: 30px;
  }
  
  .search_container { 
    padding-left: 45px; 
    background-size: auto 30%;
    background-position: 20px center; 
    font-size: 14px;
  }
  
  .search_container input {
    display: flex;
    width: 500px;
    max-width: calc(100% - 50px); 
    padding-inline: 0 20px; 
  }
  
  .about_school, .manage_your_school { 
    border-radius: 30px;
    padding-inline: 20px;
    padding-block: 0 30px; 
    gap: 30px;
  }

  .about_school .info, .manage_your_school .info { 
    gap: 15px; 
  }
  
  .about_school .school_address {
    display: flex;
    width: 100%;
    min-height: 100px;
    flex-wrap: wrap; 
  }
   
  .total_students_and_teachers {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
}