.school_report_container {
  display: none;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  /* background-color: #00000050; */
  /* overflow-y: auto; */
  padding-inline: 30px;
  padding-block: 50px 200px;
  margin-block: 0 100px;
}