.modal_container {
  display: flex;
  z-index: 10;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000b9;
}

.modal_container__modal {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 300px;
  border-radius: 30px;
  background-color: #fff;
  padding-inline: 50px;
  padding-block: 50px;
  align-items: center;
  gap: 50px;
  position: relative;
}

.modal_container__modal img {
  width: 80px;
}

.modal_container__modal button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: grey;
  color: #fff;
  font-size: 40px;
  transform: rotate(45deg);
  border-radius: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal_container__modal button:hover {
  background-color: rgb(100, 100, 100);
}

.displayed_flex {
  display: flex !important;
}

@media (max-width: 750px) {
  .modal_container { 
    padding-top: 100px; 
  }
  
  .modal_container__modal { 
    width: 98%;
    height: 300px; 
  }
}