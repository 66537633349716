
.divSideBar {
  position: relative;
  width: 270px;
  padding: 10px;
  background-color: #B2D4F9;
  height: 100%;
}
.menuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 30px;
  transition: background-color 0.3s;
}
.footerItem {
  position: absolute;
  align-items: center;
  display: flex;
  bottom: 0;
  padding: 20px;
  border-top: 2px solid #fff;
}

.sideBarImg{
  width: 36px;
  height: 36px;
}