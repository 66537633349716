body {
  background-image: url("../../public/images/bg.png");
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-attachment: fixed;
}
 
.dynamic_settings_content, .dynamic_settings_content__data_container {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;  
  margin-block: 0 30px; 
}

.left_nav_and_main_content_container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-block: 30px 0;
}

.dynamic_settings_content {
  width: calc(100% - 250px);
  height: fit-content;
  border-radius: 15px;
  gap: 20px;
  flex-direction: column; 
  margin-right: 30px;
}

.dynamic_settings_content__data_container {
  min-height: 50px;
  border-radius: 15px;
  padding-inline: 10px;
  flex-wrap: wrap;
  word-break: break-all; 
} 

.dynamic_settings_content h1 {
  display: flex;
  width: 200px;
  height: 50px;
  background-color: #000;
  color: #fff;
  font-size: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-inline: auto;
  justify-content: center;
  align-items: center;
}
 
.dynamic_settings_content__container {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center; 
  padding-inline: 20px;
  padding-block: 20px 30px;
}

.settings_pic_container {
  display: flex;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 1px solid #000;
  overflow: hidden;
}

.settings_pic_container img {
  width: 100%; 
  object-fit: cover;
}

.dynamic_settings_content h2 {
  font-size: 19px;
  color: #000;
  margin-block: 10px;
  font-weight: bold;
}

.dynamic_settings_content__container {
  display: flex;
  width: calc(100% - 60px);
  max-width: 600px;
  height: fit-content;
  background-color: #fff;  
  border-radius: 20px;
  margin-inline: 30px;
  margin-block: 0 50px;
  box-shadow: 0px 4px 4px #00000025;
} 

.dynamic_settings_content__container .dynamic_settings_content__container__form {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: fit-content; 
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center; 
  padding-inline: 50px;
  padding-block: 30px; 
}

.dynamic_settings_content__container .dynamic_settings_content__container__form h3 {
  display: flex;
  width: 100%;
  max-width: 600px;
  height: fit-content;
  font-size: 18px;
  font-weight: bold;
  justify-content: flex-start;
  margin-inline: auto;
  margin-block: 20px;
}

.dynamic_settings_content__container .dynamic_settings_content__container__form .form_input_container { 
  width: 100%;
  max-width: 600px;
}

.dynamic_settings_content__container .dynamic_settings_content__container__form .form_input_container label {
  min-height: fit-content;
}

.dynamic_settings_content__container .dynamic_settings_content__container__form .form_input_container input {
  border: 1px solid #000;
  border-radius: 15px;
}

.dynamic_settings_content__container .dynamic_settings_content__container__form button {
  display: flex;
  width: 250px;
  max-width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: #39B54A;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-block: 30px;
}

.dynamic_settings_content__container .dynamic_settings_content__container__form button:hover {
  background-color: #000; 
}

@media (max-width: 750px) { 
  .dynamic_settings_content {
    width: calc(100% - 70px);  
    margin-right: 10px;
  }
  
  .dynamic_settings_content__data_container {
    min-height: 40px;
    border-radius: 10px; 
    padding-block: 10px;
  }
  
  .dynamic_settings_content h1 { 
    width: 200px; 
    height: 40px; 
    font-size: 18px; 
  } 
  
  .dynamic_settings_content h2 {
    font-size: 17px;
  }

  .dynamic_settings_content__container {
    display: flex;
    width: calc(100% - 20px);
    max-width: 100%;
    height: fit-content;
    background-color: #fff;  
    border-radius: 15px;
    margin-inline: 30px;
    margin-block: 0 20px; 
  } 

  .dynamic_settings_content__container .dynamic_settings_content__container__form h3 { 
    width: 100%;
    font-size: 16px;
  }

  .dynamic_settings_content__container .dynamic_settings_content__container__form {  
    padding-inline: 15px;
    padding-block: 0; 
  }

  .dynamic_settings_content__container .dynamic_settings_content__container__form .form_input_container { 
    width: 100%;
    max-width: 100%; 
  }
  
  .dynamic_settings_content__container {
    display: flex;  
    height: fit-content;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;  
    padding-inline: 20px;
    padding-block: 20px 30px; 
    padding-inline: 5px;
    padding-block: 20px; 
  } 
}
