.school-info-container {
    width: 90%;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.school-info-header {
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 10px;
    border-radius: 10px 10px 0 0;
}

.school-info-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
}

.info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #00a652;
    border-radius: 35px;
}

.info-item button {
    padding: 10px 20px;
    background-color: transparent;
    border: 2px solid #00a652;
    border-radius: 35px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.info-item button:hover {
    background-color: #00a652;
    color: #fff;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .school-info-grid {
        grid-template-columns: 1fr;
        /* Stacks the grid items in a single column */
        gap: 15px;
    }

    .info-item {
        flex-direction: column;
        /* Stack text and button vertically */
        text-align: center;
        /* Center-align the text and button */
    }

    .info-item button {
        margin-top: 10px;
        width: 100%;
        /* Button takes full width on mobile */
    }
}

@media screen and (max-width: 480px) {
    .school-info-container {
        padding: 15px;
    }

    .school-info-header {
        font-size: 1.2rem;
        /* Slightly reduce font size on smaller screens */
        padding: 8px;
    }

    .info-item {
        padding: 10px;
    }

    .info-item button {
        padding: 8px 15px;
        /* Smaller button on small screens */
    }
}


.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 15px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-header h2 {
    font-size: 20px;
    margin: 0;
}

.close-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.popup-body {
    margin: 20px 0;
}

.popup-section {
    margin-bottom: 15px;
}

.current-year-box {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    display: inline-block;
}

.popup-footer {
    text-align: center;
}

.submit-btn {
    background-color: #00a652;
    color: white;
    padding: 10px 20px;
    border-radius: 35px;
    font-size: 16px;
    cursor: pointer;
    width: 200px;
}

.submit-btn:hover {
    background-color: #008a43;
}



.fieldStyle {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 35px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    border-radius: 35px;
}

.fieldStyle:focus {
    border-color: #008a43;
    outline: none;
}


.buttonProgressIndicator {
    margin-left: 8px;
    color: #fff;
}

.popup-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.popup-section {
    margin-bottom: 1rem;
}

.three-column-row {
    display: flex;
    gap: 1rem;
}

.column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.showTerm{
    color: #00a652;
}
