/* src/styles/Table.css */

.table-container {
    width: 90%;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
  }
  
  /* Filter Section Styles */
  .filter-section {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .filter-section label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .filter-section select {
    padding: 5px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .apply-filter-btn {
    padding: 5px 10px;
    border: none;
    background-color: #000;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .tag {
    display: inline-block;
    padding: 5px 10px;
    margin: 2px;
    background-color: black;
    color: white;
    border-radius: 4px;
  }
  
  .pending {
    color: #888;
  }
  
  .graded {
    color: green;
  }
  
  .edit-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #000;
    color: #fff;
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }


  /* src/styles/GradingPopup.css */

.grading-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .grading-popup {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
    text-align: left;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  h2 {
    font-size: 20px;
    color: #333;
  }
  
  h2 span, p span {
    color: #007bff;
  }
  
  .student-marks-table {
    margin-top: 20px;
  }
  
  .student-marks-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .student-marks-table th, .student-marks-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .student-marks-table input {
    width: 100%;
    padding: 5px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  