.admin_link {
  display: flex;
  width: 160px;
  height: 130px;
  background-color: #84A9D1;
  border-radius: 15px;
  padding-top: 40px;
  border: 1px solid #84A9D1;
}

.admin_link span {
  display: flex;  
  width: 100%;
  justify-content: center;
  text-align: center;
  padding-block: 15px 0;
  padding-inline: 10px;
  font-size: 15px;
}

.admin_link:hover {
  background-color: #fff; 
}

.left_side_nav {
  display: flex;
  flex-direction: column;
  width: 170px;
  height: fit-content;
  background-color: #84A9D1; 
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden; 
}

.left_sidebar_link {
  display: flex;
  width: 100%;
  height: 100px;
  background-color: #84A9D1; 
  align-items: center;
  border-bottom: 1px solid #fff;
  padding-top: 0;
}

.left_sidebar_link span {
  display: flex;  
  width: 100%; 
  height: 100%; 
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding-block: 0;
  padding-inline: 40px 10px;
  font-size: 14px;
}

.left_sidebar_link:first-of-type {
  border-top: 1px solid #84A9D1;
}

.left_sidebar_link:hover {
  background-color: #fff !important;
  border-right: 1px solid #84A9D1;
  border-bottom-color: #84A9D1;
}

@media (max-width: 750px) {
  .left_side_nav { 
    width: 50px; 
  }

  .left_sidebar_link {
    display: flex;
    width: 100%;
    height: 70px;
    background-color: #84A9D1; 
    align-items: center;
    border-bottom: 1px solid #fff;
    padding-top: 0;
  }
  
  .left_sidebar_link span {
    display: none;   
  } 
}
 
