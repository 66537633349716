.error404 {
    display: flex;
    flex-direction: column;
}

.coming-soon-container{
    width: calc(100vw -200px);
    background-color: #D9D9D9;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    color: white;
    
}

.coming-soon-sub-container-error{
    background-color: #0974e733;
    padding: 20px;
    font-size: bold;
    font-size: 30px;
    width: 70%;
    color: white;
    text-align: center;
    margin-top: 40px;
}

.coming-soon-sub-container{
    padding: 20px;
    font-size: bold;
    font-size: 30px;
    width: 70%;
    color: white;
    text-align: center;
    margin-top: 40px;
}
.coming-soon-container div{
    margin-top: 20px;
    text-align: center;
}
.coming-soon-container div:nth-child(3),
.coming-soon-container div:nth-child(2){
    color: black;
}
.coming-soon-container div:nth-child(3){
    margin-bottom: 40px;
    opacity: .5;
    font-size: 15px;
}


.error404 .container {
    display: flex;
    width: 100%; 
}
