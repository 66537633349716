.login-auth-main-container > h1{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.login-auth-main-container > div > h1{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.login-auth-container > h2{
    text-align: center;

}

.login-auth-container{
    border: 1px solid black;
    padding: 30px;
    border-radius: 15px;
    width: 90%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.login-auth-form-group{
    margin-top: 40px;
    margin-bottom: 40px;
}

.login-auth-form-group input{
    width: 100%;
    background: #E8E8E8;
    border-radius: 15px;
    padding-left: 20px;

}

.login-auth-forgot-password{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.login-auth-checkbox > div{
    margin-right: 10px;
}
.login-auth-checkbox{
    display: flex;
    gap: 8;
    align-items: center;
}
.login-auth-submit-button{
    background-color: #38B349;
    width: 100%;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 15px;
    text-align: center;

}
.login-auth-terms-and-conditions{
    text-align: center;
    margin-top: 20px;
}
.password-reset-success {
    margin: auto;
    text-align: center;
}
.password-reset-success img{
    width: 30px;
    margin: auto;
    text-align: center;
}
@media only screen and (min-width: 760px) {
    .login-auth-container{
        border: 1px solid black;
        padding: 30px;
        border-radius: 15px;
        width: 40%;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }
}