/* StudentProfile.css */
.tabs-container {
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: flex-start; /* Align tabs to the left */
  }
  

  
  .tab-panel {
    padding: 16px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 16px;
  }

  .custom-tab {
    border: 1px solid #1976d2;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    min-width: 150px;
    text-align: center;
    transition: border-color 0.3s ease;
  }

  .custom-tab.Mui-selected{
    border-color: green; /* Change active tab's border color */
    color: #1976d2;
  }
  