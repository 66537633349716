/* Existing table styles */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    text-align: left;
    position: relative;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .exam-title {
    color: #007bff;
  }
  
  .icon {
    font-size: 1.2em;
    margin-right: 10px;
  }
  
  .attempted {
    color: green;
  }
  
  .not-attempted {
    color: grey;
  }
  
  /* Adjust other styles if needed */
  