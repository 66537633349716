/* App.css */
/* mainDashboard.css */
.app {
  display: flex;
  flex-direction: column;
  /* Stacks NavBar on top of the main content */
  height: 100vh;
}

.main-content {
  display: flex;
  /* Sidebar and dashboard will still align horizontally */
  flex-grow: 1;
  /* This allows the main content to take up remaining space */
}

.dashboard {
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}


.widgets {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.statistics-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stats-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.stat-card {
  text-align: center;
}

.enquiries-container {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
}

.placeholder {
  background-color: #e0e0e0;
  padding: 20px;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .widgets {
    grid-template-columns: 1fr;
  }
}

/* Main content */
.main-content {
  display: flex;
  flex-direction: row;
  /* Sidebar and content side by side */
}

/* Sidebar (adjust width as needed) */
.sidebar {
  width: 250px;
  background-color: #f4f4f4;
  padding: 20px;
}

/* Dashboard content */
.dashboard {
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
}

/* Search container styling */
/* Search container styling */
.search-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  /* Center horizontally */
}

/* Search bar input */
.search-bar {
  width: 60%;
  /* Default width */
  padding: 12px 20px;
  padding-left: 40px;
  /* Space for the search icon */
  border: 1px solid #ccc;
  border-radius: 25px;
  /* Rounded corners */
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

/* Search icon inside the input */
.search-icon {
  position: absolute;
  left: 330px;
  /* Adjust left position based on how far you want it inside the search bar */
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}

/* Responsive handling */
@media (max-width: 768px) {
  .search-container {
    justify-content: center;
  }

  .search-bar {
    width: 100%;
    /* Full width on smaller screens */
    padding-left: 35px;
    /* Adjust padding on smaller screens */
  }

}



/* Styling the dashboard */
.enquiries-cards-container {
  display: grid;
  grid-gap: 20px;
  /* Space between the cards */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  /* Ensure cards flow horizontally */
  width: 100%;
  /* Make sure the container spans the full width */
  padding: 20px;
  box-sizing: border-box;
  /* Ensure padding is included in the width */
}

.enquiry-card {
  background-color: #B2D4F9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: center;
}

.enquiry-card:hover {
  transform: translateY(-5px);
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center icon and text */
  margin-bottom: 10px;
}

.card-header-icon {
  width: 40px;
  margin-right: 10px;
}

.card-header-text {
  font-size: 18px;
  font-weight: bold;
}

.subheader {
  font-size: 16px;
  margin-bottom: 20px;
}



.outline-button:hover {
  background-color: #007bff;
  color: white;
}

/* Media query for smaller screens to stack cards vertically */
@media screen and (max-width: 768px) {
  .enquiries-cards-container {
    grid-template-columns: 1fr;
    /* Stack cards vertically on small screens */
  }
}

.table-container {
  max-width: 90%;
  /* You can adjust the width as needed */
  margin: 20px auto;
  /* Center the table horizontally and add top/bottom margins */
  padding: 20px;
  /* Add padding around the table */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Optional: Add a shadow for better aesthetics */
  background-color: #fff;
  /* Optional: Add a background color */
  border-radius: 8px;
  /* Optional: Round the corners */
}

table {
  width: 100%;
  border-collapse: collapse;
  padding-left: 10px;
}

thead {
  background-color: #f2f2f2;
}

th, td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
}

td img {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 24px;
}


.viewMore {
  text-align: center;
  vertical-align: middle;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.resolved {
  color: green;
  font-weight: bold;
}

.pending {
  color: red;
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  /* Aligns buttons to the right */
  margin-top: 20px;
}

.pagination button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button.active {
  background-color: #000;
  color: #fff;
}

.pagination button:hover {
  background-color: #000;
  color: #fff;
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.controls label {
  margin-right: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #f0f8ff;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.details-grid div {
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.controls {
  display: flex;
  gap: 10px;
}

.inline-label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.short-field {
  height: 34px;
  padding: 2px 4px;
  font-size: 14px;
  width: 100px;
}

/* SuperAdmin School Enrolment CSS */

.divOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.divModal {
  background-color: #d4e2f0;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.closeIcon {
  font-size: 24px;
  cursor: pointer;
}

.divTextArea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  resize: none;
}

.approveButton {
  padding: 10px 20px;
  background-color: transparent;
  color: #000;
  border: 2px solid #000;
  border-radius: 35px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}


/* Edit School Details */
.divEditOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.divEditModal {
  background-color: #B2D4F9;
  padding: 30px;
  border-radius: 8px;
  width: 600px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.closeIcon {
  font-size: 24px;
  cursor: pointer;
}

.divEditForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 15px;
}

.divEditColumn {
  display: flex;
  flex-direction: column;
}

.divButtonGroup {
  grid-column: span 2;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.divUpdateButton {
  padding: 10px 20px;
  background-color: transparent;
  color: #000;
  border: 2px solid #000;
  border-radius: 35px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.divUpdateButtonHover {
  background-color: #007BFF;
  color: #fff;
}

.editCancelButton {
  padding: 10px 20px;
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 35px;
  cursor: pointer;
}

.divHeading {
  font-size: 20px;
}


/* Edit Enquiry Modal 8 */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  background: #B2D4F9;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.editCloseButton {
  background: none;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.statusChange {
  margin: 20px 0;
}

.statusChange label {
  margin-right: 20px;
}

.noteArea {
  width: 100%;
  height: 80px;
  margin-top: 10px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.editSubmitButton {
  background-color: #000;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  margin-top: 10px;
}

.enquiriesImg {
  width: 24px;
  height: 24px;
}

.buttonProgressIndicator {
  margin-left: 8px;
  color: green;
}

.hidden {
  display: none;
}