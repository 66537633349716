.my-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
}


.card-pending-requests {
    background-color: #fff;
    color: #0078FA;
    border: 1px solid #0078FA;
}



.card-approved-requests {
    background-color: #fff;
    color: #39B54A;
}

.card-rejected-requests {
    background-color: #fff;
    color: #FF0000;
}

/* Hover effect for all cards */
.my-card:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/* Icon styles */
.card-icon {
    width: 50px;
    height: 50px;
}