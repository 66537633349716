.school_report {
  display: flex;
  max-width: min(900px, 100%);
  height: fit-content;
  flex-direction: column;
  background-color: #fff;
  border-radius: 30px;
  padding-inline: 30px;
  padding-block: 20px 30px;
  margin-inline: auto;
  margin-block: 0 50px;
  box-shadow: 4px 4px 4px #00000025,  -4px -4px 4px #00000025;
}

.close_report_container {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: flex-end; 
  padding: 0 !important;
}

.close_report_container .close_parent {
  display: flex;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  color: #000;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  cursor: pointer; 
}

.school_report_heading {
  display: flex;
  width: 100%;
  height: 30px; 
  font-size: 18px;
  padding-inline: 40px 20px;
  align-items: center;
  background-image: url("../../public/images/school_report_icon.svg");
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.school_report__filter_container {
  display: flex;
  width: 100%;
}
.school_report__filter_container form {
  display: flex;
  width: 100%;
  min-height: 30px; 
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  row-gap: 5px;
  flex-wrap: wrap;
}

.school_report__filter_container select, .school_report__filter_container option, .school_report__filter_container input {
  display: flex;
  width: 90px;
  height: 30px;
  background-color: #d9d9d9;
  border-radius: 3px;
  margin: 0;
}
 
.school_report__filter_container button {
  display: flex;
  width: fit-content;
  height: 30px;
  padding-inline: 20px;
  border: 1px solid #000;
  border-radius: 15px;
  align-items: center;
}

.school_report__filter_container button:hover {
  background-color: #000;
  color: #fff;
}
 
.print_and_download_container {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
  margin-block: 50px 10px;
}
 
.print_and_download_container button:first-of-type {
  display: flex;
  width: fit-content;
  height: 30px;
  padding-inline: 23px 0;
  font-size: 20px;
  font-weight: bold; 
  align-items: center;
  background-image: url("../../public/images/print_icon.svg");
  background-size: auto 65%;
  background-repeat: no-repeat;
  background-position: 0 center;
}

.print_and_download_container button:first-of-type:hover {
  color: grey;
}

.print_and_download_container button:last-of-type {
  display: flex;
  width: fit-content;
  height: 30px;
  padding-inline: 20px;
  background-color:#000;
  border: 1px solid #000;
  color: #fff;
  border-radius: 15px;
  align-items: center;
}

.print_and_download_container button:hover {
  background-color: #fff;
  color: #000;
}

.school_report_main_data {
  display: flex;
  width: 100%;
  height: fit-content;
  border-top: 1px solid #3786DB;
  border-bottom: 1px solid #3786DB;
  border-left: 8px solid #3786DB;
  border-right: 8px solid #3786DB;
  flex-direction: column;
  padding-inline: 30px;
  padding-block: 30px 50px;
  background-color: #f1f1f1;
}

.school_logo_and_name_container {
  display: flex;
  width: 100%;
  height: 100px;
  gap: 20px;
}

.school_logo_and_name_container img {
  display: flex;
  width: 180px;
  height: 100%; 
  object-fit: cover;
  background-color: #d9d9d9; 
}

.school_logo_and_name_container h3 {
  display: flex;
  width: fit-content;
  height: 100px;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  text-transform: uppercase;
}

.school_report__details_container {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  margin-block: 20px 30px;
}

.school_report__details_container h3 {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: #3786DB;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  align-items: center;
  padding-inline: 20px;
}

.school_report__details_container .data {
  display: flex;
  width: 100%;
  height: fit-content;
  padding-inline: 15px;
  padding-block: 15px;
  background-color: #fff;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 5px;
}

.school_report__details_container .data span {
  display: flex;
  width: 250px;
  height: fit-content;
  font-size: 14px; 
}

.school_report_details_and_grading_system_container {
  display: flex;
  width: 100%;
  height: fit-content;
  margin-block: 0 20px;
  flex-wrap: wrap;
  gap: 20px;  
}

.school_report_details_and_grading_system_container__left {
  display: flex;
  width: calc(100% - 180px);
  height: 100%;
  flex-direction: column;
  gap: 20px; 
}

.school_report_details_and_grading_system_container__left_1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: fit-content; 
}

.school_report_details_and_grading_system_container__left .results {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;  
}

.school_report_details_and_grading_system_container__left_1 h3 { 
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #3786DB;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  align-items: center; 
  justify-content: space-between;
}
 
.school_report_details_and_grading_system_container__left .results p {
  display: flex;
  width: 100%;
  height: fit-content;
  /* column-gap: 5px; */
  justify-content: space-between;
}

.school_report_details_and_grading_system_container__left .results p span {
  font-size: 14px;
  color: #000;
  padding-block: 5px;
}

.school_report_details_and_grading_system_container__left_1 h3 span:first-of-type, .school_report_details_and_grading_system_container__left .results p span:first-of-type {
  width: 300px;
  padding-inline: 10px 5px;
}

.school_report_details_and_grading_system_container__left_1 h3 span:nth-of-type(2), .school_report_details_and_grading_system_container__left .results p span:nth-of-type(2) {
  width: 50px;
}

.school_report_details_and_grading_system_container__left_1 h3 span:nth-of-type(3), .school_report_details_and_grading_system_container__left .results p span:nth-of-type(3) {
  width: 50px;
}

.school_report_details_and_grading_system_container__left_1 h3 span:nth-of-type(4), .school_report_details_and_grading_system_container__left .results p span:nth-of-type(4) {
  width: 160px;
}

.school_report_details_and_grading_system_container__left .results p span:first-of-type {
  display: flex; 
  height: fit-content;
  background-color: #B2D4F9;
}

.school_report_details_and_grading_system_container__left .results p span:nth-of-type(2) {
  display: flex; 
  height: fit-content;
  justify-content: center;
  background-color: #fff;
}

.school_report_details_and_grading_system_container__left .results p span:nth-of-type(3) {
  display: flex; 
  height: fit-content;
  justify-content: center;
  background-color: #fff;
}

.school_report_details_and_grading_system_container__left .results p span:nth-of-type(4) {
  display: flex; 
  height: fit-content;
  background-color: #fff;
  padding-inline: 10px 0;
}

.school_report_details_and_grading_system_container__right {
  display: flex;
  width: 160px;
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
}

.school_report_details_and_grading_system_container__right h3 { 
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #3786DB;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  align-items: center;
  padding-inline: 15px;
  gap: 10px;
}

.school_report_details_and_grading_system_container__right .data {
  display: flex;
  width: 100%;
  height: calc(100% - 40px);
  flex-wrap: wrap; 
}

.school_report_details_and_grading_system_container__right .data p {
  display: flex;
  width: 100%; 
}

.school_report_details_and_grading_system_container__right 
.data p span:first-of-type {
  display: flex;
  width: 60px;
  background-color: #B2D4F9;
  font-size: 16px;
  color: #000;
  justify-content: center;
  align-items: center;
  padding-block: 5px;
}

.school_report_details_and_grading_system_container__right 
.data p span:last-of-type {
  display: flex;
  width: calc(100% - 60px);
  background-color: #fff;
  font-size: 14px;
  color: #000;
  justify-content: center;
  align-items: center;
  padding-block: 5px;
}

.school_report_details_and_grading_system_container__left_2 {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
}

.school_report_details_and_grading_system_container__left_2 h3 {
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #3786DB;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  align-items: center;
  padding-inline: 15px;
}

.school_report_details_and_grading_system_container__left_2 .data {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column; 
}

.school_report_details_and_grading_system_container__left_2 .data p {
  display: flex;
  width: 100%;
  min-height: 40px;
  justify-content: space-between;
}

.school_report_details_and_grading_system_container__left_2 .data p span:first-of-type {
  display: flex;
  background-color: #B2D4F9;
  width: 415px;
  min-height: 100%;
  color: #000;
  padding-inline: 15px;
  font-size: 14px;
  align-items: center;
}

.school_report_details_and_grading_system_container__left_2 .data p span:last-of-type {
  display: flex;
  background-color: #fff;
  width: 160px;
  min-height: 100%;
  color: #000;
  padding-inline: 15px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
}

.school_report_details_and_grading_system_container__left_3 h3 { 
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #3786DB;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  align-items: center; 
  gap: 5px;
}

.school_report_details_and_grading_system_container__left_3 h3 span:first-of-type, .school_report_details_and_grading_system_container__left_3 .data span:first-of-type {
  display: flex; 
  width: 465px;
  padding-inline: 15px 0;
}

.school_report_details_and_grading_system_container__left_3 h3 span:nth-of-type(2), .school_report_details_and_grading_system_container__left_3 .data span:nth-of-type(2), 
.school_report_details_and_grading_system_container__left_3 h3 span:nth-of-type(3), .school_report_details_and_grading_system_container__left_3 .data span:nth-of-type(3) { 
  display: flex;
  width: 50px; 
  justify-content: center; 
}  

.school_report_details_and_grading_system_container__left_3 .data span:nth-of-type(2),.school_report_details_and_grading_system_container__left_3 .data span:nth-of-type(3) {  
  background-color: #fff; 
  height: 50px;
  align-items: center;
}  

.school_report_details_and_grading_system_container__left_3 .data {
  display: flex;
  width: 100%;
  height: fit-content; 
  gap: 5px;
  justify-content: space-between;
}

.school_report_details_and_grading_system_container__left_3 .data span:first-of-type {
  display: flex; 
  height: fit-content;
  height: 50px;
  align-items: center;
  padding-inline: 15px 0;
  background-color: #B2D4F9;
}

.school_stamp_and_head_comment_container {
  display: flex;
  width: 100%;
  height: fit-content; 
  justify-content: space-between;
}

.school_stamp {
  display: flex;
  width: 200px;
  height: 100px;
  background-color: #d9d9d9;
  justify-content: center; 
  flex-direction: column;
  padding-inline: 20px 0;
  gap: 10px;
  transform: rotate(-2deg);
}

.school_stamp span {
  font-size: 20px;
  font-weight: bold;
}

.head_comment {
  display: flex;
  width: 500px;
  height: fit-content;
  flex-direction: column;
}

.head_comment h3 {
  display: flex;
  width: 100%;
  height: 40px; 
  background-color: #3786DB;
  font-size: 12px;
  color: #fff;
  align-items: center;
  padding-inline: 15px;
  font-weight: bold;
}

.head_comment p {
  display: flex;
  width: 100%;
  min-height: 50px; 
  background-color: #fff;
  font-size: 14px; 
  align-items: center;
  padding-inline: 15px; 
  padding-block: 5px;
}

@media (max-width: 750px) {
  .school_report {
    display: flex;
    max-width: 100%;  
    padding-block: 15px 20px; 
  } 

  /* .school_report_heading {
    display: flex;
    width: 100%;
    height: 30px; 
    font-size: 18px;
    padding-inline: 40px 20px;
    align-items: center;
    background-image: url("../../public/images/school_report_icon.svg");
    background-repeat: no-repeat;
    background-size: auto 100%;
  } */
  
  .school_report__filter_container form { 
    row-gap: 15px; 
  } 
   
  .print_and_download_container {
    display: flex;
    width: 100%;
    min-height: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    margin-block: 40px 10px;
  } 
  
  .school_logo_and_name_container {
    display: flex;
    width: 100%;
    min-height: 80px;
    gap: 20px; 
    overflow: hidden;
  } 
  
  .school_logo_and_name_container h3 { 
    height: 100%;
    font-size: 18px;  
  }
  
  .school_report__details_container { 
    margin-block: 15px 20px; 
  }
  
  .school_report__details_container h3 { 
    height: 40px; 
    font-size: 16px; 
    padding-inline: 15px;
  } 
  
  .school_report__details_container .data span { 
    flex-grow: 1; 
  }
  
  .school_report_details_and_grading_system_container {
    display: flex;
    width: 100%;
    height: fit-content;
    margin-block: 0 20px;
    flex-wrap: wrap;
    gap: 20px;  
  }
  
  .school_report_details_and_grading_system_container__left { 
    width: 100%;
    height: fit-content;
    flex-direction: column;
    gap: 15px;  
  }
  
  .school_report_details_and_grading_system_container__left_1 {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: fit-content;  
  }
    
  .school_report_details_and_grading_system_container__left .results p {
    display: flex;
    width: 100%;
    min-height: fit-content !important; 
    height: fit-content; 
    justify-content: space-between;
  }
  
  .school_report_details_and_grading_system_container__left .results p span {
    height: auto !important;
    align-items: center; 
    font-size: 12px;
  }

  .school_report_details_and_grading_system_container__left_1 h3 { 
    display: flex;
    width: 100%;
    height: 40px; 
    font-size: min(12px, 60%);
    font-weight: bold;
    color: #fff;
    align-items: center; 
    justify-content: space-between;
    gap: 5px;
  }
  
  .school_report_details_and_grading_system_container__left_1 h3 span:first-of-type, .school_report_details_and_grading_system_container__left .results p span:first-of-type {
    width: max(150px, calc(100% - 300px));
    padding-inline: 10px 5px;  
  }
  
  .school_report_details_and_grading_system_container__left_1 h3 span:nth-of-type(2), .school_report_details_and_grading_system_container__left .results p span:nth-of-type(2) {
    width: 40px;  
    justify-content: center !important; 
  }
  
  .school_report_details_and_grading_system_container__left_1 h3 span:nth-of-type(3), .school_report_details_and_grading_system_container__left .results p span:nth-of-type(3) {
    width: 40px;
  }
  
  .school_report_details_and_grading_system_container__left_1 h3 span:nth-of-type(4), .school_report_details_and_grading_system_container__left .results p span:nth-of-type(4) {
    width: max(100px, calc(100% - 300px)); 
  } 
   
  .school_report_details_and_grading_system_container__right 
  .data p span:first-of-type { 
    width: max(60px, 40%);  
    font-size: 12px; 
  }
  
  .school_report_details_and_grading_system_container__right 
  .data p span:last-of-type { 
    width: calc(100% - 100px); 
    padding-block: 5px;
    font-size: 12px;
  }
  
  .school_report_details_and_grading_system_container__left_2 .data p span:first-of-type { 
    width: calc(100% - 90px);
    font-size: 12px; 
  }
  
  .school_report_details_and_grading_system_container__left_2 .data p span:last-of-type { 
    width: 90px; 
    padding-inline: 0; 
    font-size: 12px; 
  }
   
  .school_report_details_and_grading_system_container__left_3 h3 span:first-of-type, .school_report_details_and_grading_system_container__left_3 .data span:first-of-type {
    display: flex; 
    width: calc(100% - 90px);
    padding-inline: 15px 0;
  }
  
  .school_report_details_and_grading_system_container__left_3 h3 span:nth-of-type(2), .school_report_details_and_grading_system_container__left_3 .data span:nth-of-type(2), 
  .school_report_details_and_grading_system_container__left_3 h3 span:nth-of-type(3), .school_report_details_and_grading_system_container__left_3 .data span:nth-of-type(3) {  
    width: 40px;  
    font-size: 12px;
  }  
   
  .school_report_details_and_grading_system_container__left_3 .data span:first-of-type { 
    font-size: 12px;
  }
  
  .school_stamp_and_head_comment_container {
    margin-block: 10px 0;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 30px; 
  }
   
  .school_stamp span {
    font-size: 18px; 
  }
  
  .head_comment { 
    width: 100%;  
    height: fit-content; 
  } 
  
  .head_comment p {
    height: fit-content; 
    font-size: 12px; 
    word-break: break-all;
    padding-block: 5px;
    flex-wrap: wrap; 
  }
}



